import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'


const articleApi = {
	getList: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=getGszsList',
	getWxInfo: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=getWxArtInfo',
	deliv: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=newWxArt',
	delWxArticle: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=delWxArt',

	/*水质*/
	getWqrList: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=getWaterQualityList',
	getWqrType: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=getWaterQualityType',
	delWqrType: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=delWaterQuality',
	getWqrInfo: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=getWaterQualityInfo',
	editWqrInfo: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=addWaterQuality',

	/*用水知识库*/
	getklList: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=getKnowledgeList',
	getklType: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=getKnowledgeType',
	getklInfo: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=getKnowledgeInfo',
	editklInfo: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=addKnowledge',
	delklInfo: (isProd ? '' : '/api') + '/newAdmin.php?c=News&a=delKnowledge',
	
	/*banner */
	getBannerList: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=getBannerList',
	editBanner: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=addBanner',
	setStatus: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=setBannerState',
	
	/*客服题库 */
	getQusList: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=getQuestionBankList',
	editQus: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=addQuestionBank',
	delQus: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=delQuestionBank',
  
  _getDocList:(isProd ? '' : '/api') + '/newAdmin.php?c=Article&a=getArticleList',
  _editDoc:(isProd ? '' : '/api') + '/newAdmin.php?c=Article&a=editArticle',
  _delDoc:(isProd ? '' : '/api') + '/newAdmin.php?c=Article&a=del',
  _getDocInfo:(isProd ? '' : '/api') + '/newAdmin.php?c=Article&a=getArticleInfo'
}

/*指南和下载*/
export function _getDocList(parameter) {
	return request({
		url: articleApi._getDocList,
		method: 'post',
		data: parameter
	})
}
export function _editDoc(parameter) {
	return request({
		url: articleApi._editDoc,
		method: 'post',
		data: parameter
	})
}
export function _delDoc(parameter) {
	return request({
		url: articleApi._delDoc,
		method: 'post',
		data: parameter
	})
}
export function _getDocInfo(parameter) {
	return request({
		url: articleApi._getDocInfo,
		method: 'post',
		data: parameter
	})
}
/*题库列表*/
export function getQusList(parameter) {
	return request({
		url: articleApi.getQusList,
		method: 'post',
		data: parameter
	})
}

/*编辑题库*/
export function editQus(parameter) {
	return request({
		url: articleApi.editQus,
		method: 'post',
		data: parameter
	})
}


/*删除题库*/
export function delQus(parameter) {
	return request({
		url: articleApi.delQus,
		method: 'post',
		data: parameter
	})
}



/*banner 列表*/
export function getBannerList(parameter) {
	return request({
		url: articleApi.getBannerList,
		method: 'post',
		data: parameter
	})
}

/*banner 新增编辑*/
export function editBanner(parameter) {
	return request({
		url: articleApi.editBanner,
		method: 'post',
		data: parameter
	})
}

/*banner 修改状态*/
export function setStatus(parameter) {
	return request({
		url: articleApi.setStatus,
		method: 'post',
		data: parameter
	})
}



/*用水知识列表*/
export function getklList(parameter) {
	return request({
		url: articleApi.getklList,
		method: 'post',
		data: parameter
	})
}

/*用水知识分类*/
export function getklType(parameter) {
	return request({
		url: articleApi.getklType,
		method: 'post',
		data: parameter
	})
}
/*用水知识详情*/
export function getklInfo(parameter) {
	return request({
		url: articleApi.getklInfo,
		method: 'post',
		data: parameter
	})
}

/*用水知识新增编辑*/
export function editklInfo(parameter) {
	return request({
		url: articleApi.editklInfo,
		method: 'post',
		data: parameter
	})
}

/*用水知识删除*/
export function delklInfo(parameter) {
	return request({
		url: articleApi.delklInfo,
		method: 'post',
		data: parameter
	})
}




/*水质报告列表*/
export function getWqrList(parameter) {
	return request({
		url: articleApi.getWqrList,
		method: 'post',
		data: parameter
	})
}

/*水质报告分类*/
export function getWqrType(parameter) {
	return request({
		url: articleApi.getWqrType,
		method: 'post',
		data: parameter
	})
}
/*水质报告删除*/
export function delWqrType(parameter) {
	return request({
		url: articleApi.delWqrType,
		method: 'post',
		data: parameter
	})
}
/*水质报告详情*/
export function getWqrInfo(parameter) {
	return request({
		url: articleApi.getWqrInfo,
		method: 'post',
		data: parameter
	})
}
/*水质报告编辑*/
export function editWqrInfo(parameter) {
	return request({
		url: articleApi.editWqrInfo,
		method: 'post',
		data: parameter
	})
}

/*列表*/
export function getList(parameter) {
	return request({
		url: articleApi.getList,
		method: 'post',
		data: parameter
	})
}

/*发布*/
export function deliv(parameter) {
	return request({
		url: articleApi.deliv,
		method: 'post',
		data: parameter
	})
}

/*获取信息*/
export function getWxInfo(parameter) {
	return request({
		url: articleApi.getWxInfo,
		method: 'post',
		data: parameter
	})
}


/*删除*/
export function delWxArticle(parameter) {
	return request({
		url: articleApi.delWxArticle,
		method: 'post',
		data: parameter
	})
}
